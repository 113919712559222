<template>
<div
    v-if="files.length > 0"
    class="kt-portlet kt-portlet--height-fluid"
>
    <div class="kt-portlet__head ">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ files.length }} Unprocessed Files
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-portlet__content">
            <div class="form-group form-group-last">
                <div class="alert alert-secondary">
                    <div class="alert-icon">
                        <i class="flaticon-warning kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        Never re-save or edit data files in excel before uploading,
                        otherwise the files will be rejected by the system.
                    </div>
                </div>
            </div>

            <div
                class="tab-pane active"
                :style="files.length <= 1 ? 'max-height: 80px;' : 'max-height: 140px;'"
                style="overflow-y: scroll;"
            >
                <div class="kt-widget4">
                    <div
                        v-for="file in files"
                        :key="`errorBin_${file.syncFileErrorId}`"
                        class="kt-widget4__item"
                    >
                        <div class="kt-widget4__pic kt-widget4__pic--icon">
                            <SVGIcon
                                name="file-error"
                                hex-color="#fd397a"
                            />
                        </div>
                        <a
                            :href="file.fileBucketPath"
                            target="_blank"
                            class="kt-widget4__title kt-font-danger"
                        >
                            {{ file.fileName }}

                            <div class="kt-label-font-color-1 kt-font-sm">
                                Uploaded {{ file.relativeDate }} by {{ file.author }}
                            </div>
                            <div
                                v-if="file.fileError"
                                class="kt-font-sm"
                            >
                                {{ file.fileError }}
                            </div>
                        </a>

                        <div class="kt-widget4__tools">
                            <a
                                href="#"
                                title="Delete File"
                                class="btn btn-clean btn-icon btn-sm"
                                @click.stop.prevent=""
                            >
                                <i
                                    class="flaticon2-delete"
                                    @click.stop.prevent="deleteFile(file)"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as network from '../../network';

export default {
    name: 'ErrorBin',
    props: {
        errorFiles: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            files: [],
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    watch: {
        errorFiles() {
            this.files = this.errorFiles;
        },
    },
    mounted() {
        this.files = this.errorFiles;
    },
    methods: {
        deleteFile(file) {
            const v = this;
            const { schoolId, schoolTermId } = this.user.school;
            const { syncFileErrorId } = file;
            const params = {
                url: { schoolId, schoolTermId, syncFileErrorId },
            };
            network.storage.deleteSyncFileError(params, (err) => {
                if (err) return v.showError(err);

                v.files = v.files.filter((f) => f.syncFileErrorId !== syncFileErrorId);
            });
        },
    },
};

</script>

<style scoped>

.kt-widget17 .kt-widget17__stats {
    flex-wrap: wrap;
    display: flex;
    margin: -4.3rem auto 0 auto;
    position: relative;
    width: 95%;
}

.kt-widget17__items {
    float: left;
}
.kt-widget17__item {
    text-align: center;
    min-width: 140px;
    max-width: 140px;
    width: 140px;
}

.kt-widget17__subtitle, .kt-widget17__desc {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: block;
}
</style>
