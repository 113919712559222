var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.files.length > 0
    ? _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c("h3", { staticClass: "kt-portlet__head-title" }, [
              _vm._v(" " + _vm._s(_vm.files.length) + " Unprocessed Files "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c("div", { staticClass: "kt-portlet__content" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "tab-pane active",
                staticStyle: { "overflow-y": "scroll" },
                style:
                  _vm.files.length <= 1
                    ? "max-height: 80px;"
                    : "max-height: 140px;",
              },
              [
                _c(
                  "div",
                  { staticClass: "kt-widget4" },
                  _vm._l(_vm.files, function (file) {
                    return _c(
                      "div",
                      {
                        key: `errorBin_${file.syncFileErrorId}`,
                        staticClass: "kt-widget4__item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-widget4__pic kt-widget4__pic--icon",
                          },
                          [
                            _c("SVGIcon", {
                              attrs: {
                                name: "file-error",
                                "hex-color": "#fd397a",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "kt-widget4__title kt-font-danger",
                            attrs: {
                              href: file.fileBucketPath,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(file.fileName) + " "),
                            _c(
                              "div",
                              {
                                staticClass: "kt-label-font-color-1 kt-font-sm",
                              },
                              [
                                _vm._v(
                                  " Uploaded " +
                                    _vm._s(file.relativeDate) +
                                    " by " +
                                    _vm._s(file.author) +
                                    " "
                                ),
                              ]
                            ),
                            file.fileError
                              ? _c("div", { staticClass: "kt-font-sm" }, [
                                  _vm._v(" " + _vm._s(file.fileError) + " "),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c("div", { staticClass: "kt-widget4__tools" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-clean btn-icon btn-sm",
                              attrs: { href: "#", title: "Delete File" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "flaticon2-delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.deleteFile(file)
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group form-group-last" }, [
      _c("div", { staticClass: "alert alert-secondary" }, [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " Never re-save or edit data files in excel before uploading, otherwise the files will be rejected by the system. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }